import CloudUploadIcon from "@mui/icons-material/CloudUploadRounded";
import { Button, Card, CardActions, CardHeader, CircularProgress, Grid } from "@mui/material";
import { AclNamespaces, AclPermissions, MethodFileDto } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Authorization from "../../components/Authorization";
import DeleteFileDialog from "../../components/dialogues/DeleteFileDialog";
import UploadFileDialog from "../../components/dialogues/UploadFileDialog";
import DeleteIcon from "../../components/icons/DeleteIcon";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

import { CellContext, createColumnHelper } from "@tanstack/react-table";
import TableDateCell, { DateFormat } from "../../components/table/CellRenderer/TableDateCell";
import TableHeaderCell from "../../components/table/CellRenderer/TableHeaderCell";
import TableLinkCell from "../../components/table/CellRenderer/TableLinkCell";
import TableUserCell from "../../components/table/CellRenderer/TableUserCell";
import TableIconButton from "../../components/table/TableIconButton";
import UncontrolledTable from "../../components/table/UncontrolledTable/UncontrolledTable";
import { useCurrentClient } from "../../domain/client";
import { useDeleteMethodFile, useMethodFiles, useUploadMethodFile } from "../../domain/methods/files";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useAllUsers } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { baseUrl } from "../../lib/api";
import { formatSize } from "../../lib/formatters";
import { translationKeys } from "../../translations/main-translations";

interface MethodFilesProps {
    methodId: number;
}

const columnHelper = createColumnHelper<MethodFileDto>();

const renderFileName = (props: CellContext<MethodFileDto, string>) => (
    <TableLinkCell external link={`${baseUrl}static/${props.row.original.hash}`} {...props} />
);

const renderDate = (props: CellContext<MethodFileDto, Date>) => <TableDateCell format={DateFormat.DateTime} {...props} />;

const MethodFiles = ({ methodId }: MethodFilesProps) => {
    const { t } = useTranslation();

    const filesQuery = useMethodFiles({ methodId });
    const uploadFileMutation = useUploadMethodFile();
    const deleteFileMutation = useDeleteMethodFile();

    const client = useCurrentClient();
    const users = useAllUsers();
    const hasUploadPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Method,
        permission: AclPermissions.FileUpload,
        fact: {},
    });
    const uploadDialog = useDialog();
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [isValidUpload, setIsValidUpload] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<number>();

    const handleUpload = () => {
        if (fileToUpload !== null) {
            uploadFileMutation.mutate({ methodId, file: fileToUpload });
        }

        uploadDialog.close();
        setFileToUpload(null);
        setIsValidUpload(false);
    };

    const renderDeleteButton = useCallback(
        ({ getValue, row }: CellContext<MethodFileDto, number>) => (
            <TableIconButton onClick={() => setFileToDelete(getValue())} disabled={row.original.clientId !== client.id}>
                <DeleteIcon />
            </TableIconButton>
        ),
        [client.id],
    );

    const renderUser = useCallback((props: CellContext<MethodFileDto, number>) => <TableUserCell {...props} users={users} />, [users]);

    const columns = useMemo(
        () => [
            columnHelper.accessor((file) => `${file.filename} (${formatSize(file.size ?? 0)})`, {
                header: TableHeaderCell,
                meta: { label: t(translationKeys.VDLANG_FILES_COLUMN_FILENAME) },
                id: "name",
                cell: renderFileName,
            }),
            columnHelper.accessor((file) => new Date(file.createdAt ?? 0), {
                header: TableHeaderCell,
                meta: { label: t(translationKeys.VDLANG_FILES_COLUMN_DATE) },
                cell: renderDate,
                id: "createdAt",
                sortingFn: "datetime",
            }),
            columnHelper.accessor("createdById", {
                header: TableHeaderCell,
                meta: { label: t(translationKeys.VDLANG_FILES_COLUMN_USER) },
                cell: renderUser,
                id: "createdById",
                sortingFn: "alphanumeric",
            }),
            ...(hasUploadPermissionQuery.isSuccess && hasUploadPermissionQuery.data.hasPermission
                ? [
                      columnHelper.accessor("id", {
                          header: TableHeaderCell,
                          meta: { label: t(translationKeys.VDLANG_FILES_COLUMN_DELETE) },
                          cell: renderDeleteButton,
                          enableSorting: false,
                          enableResizing: false,
                          size: 75,
                      }),
                  ]
                : []),
        ],
        [t, renderUser, hasUploadPermissionQuery.isSuccess, hasUploadPermissionQuery.data?.hasPermission, renderDeleteButton],
    );
    if (!hasUploadPermissionQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Card>
            <CardHeader title={t(translationKeys.VDLANG_METHOD_FILES)} />
            <DeleteFileDialog
                item="file"
                open={fileToDelete !== undefined}
                onClose={() => setFileToDelete(undefined)}
                file={filesQuery.data?.find((f) => f.id === fileToDelete)}
                onDelete={() => fileToDelete !== undefined && deleteFileMutation.mutate({ methodId, fileId: fileToDelete })}
                translate={t}
            />
            <UploadFileDialog
                open={uploadDialog.isOpen}
                onClose={uploadDialog.close}
                onUpload={handleUpload}
                file={fileToUpload}
                updateFile={(file, isValid) => {
                    setFileToUpload(file);
                    setIsValidUpload(isValid);
                }}
                primaryDisabled={!isValidUpload}
                translate={t}
            />
            {filesQuery.isSuccess ? (
                <UncontrolledTable
                    data={filesQuery.data}
                    columns={columns}
                    itemName="files"
                    pageSizeOptions={[10, 25, 50]}
                    defaultPageSize={25}
                    noDataText={t(translationKeys.VDLANG_NO_FILES)}
                />
            ) : (
                <LoadingAnimation />
            )}
            <Authorization namespace={AclNamespaces.Method} permission={AclPermissions.FileUpload}>
                <CardActions>
                    <Button
                        color="primary"
                        onClick={uploadDialog.open}
                        startIcon={<CloudUploadIcon />}
                        disabled={uploadFileMutation.isLoading}
                    >
                        {t(translationKeys.VDLANG_METHOD_UPLOAD_FILE)}
                    </Button>
                    {uploadFileMutation.isLoading && (
                        <Grid item>
                            <CircularProgress variant="determinate" value={uploadFileMutation.progress} />
                        </Grid>
                    )}
                </CardActions>
            </Authorization>
        </Card>
    );
};

export default MethodFiles;
