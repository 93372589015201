import ExpandMore from "@mui/icons-material/ExpandMoreRounded";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardHeader,
    Skeleton,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { FeedbackType, SubTaskStatus } from "api-shared";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import FeedbackDialog from "../../components/dialogues/FeedbackDialog";
import InfoIcon from "../../components/icons/InfoIcon";
import MarkdownMentions from "../../components/MarkdownMentions";
import SubtaskStatusIcon from "../../components/tasks/SubtaskStatusIcon";
import { useActivityTemplate } from "../../domain/methods/activity-template";
import useDialog from "../../hooks/useDialog";
import { useLanguage } from "../../hooks/useLanguage";
import { translateFromProperty } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";

const NoWrapTypography = styled(Typography)({
    overflowWrap: "anywhere",
});

const ContentWithBorders = styled("div")(({ theme }) => ({
    padding: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
        paddingBottom: 0,
        marginBottom: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const SkeletonRow = styled("div")(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    borderBottom: `${theme.palette.divider} 1px solid`,
}));

const ActivityTemplateRowSkeletonBars = () => (
    <>
        {[...Array(5).keys()].map((i) => (
            <SkeletonRow key={`skeleton_${i}`}>
                <Skeleton />
            </SkeletonRow>
        ))}
    </>
);

interface ActivityTemplateProps {
    methodId: number;
}

const ActivityTemplate = ({ methodId }: ActivityTemplateProps) => {
    const language = useLanguage();
    const { t: translate } = useTranslation();
    const feedbackDialog = useDialog();

    const activityTemplateQuery = useActivityTemplate({ methodId });
    const activityTemplateItems = [...(activityTemplateQuery.data?.items ?? [])].sort((a, b) => a.order - b.order);
    const noActivitiesInTemplate = activityTemplateItems.length === 0;

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <span>{translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE)}</span>
                        <InfoIcon title={translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_HINT)} />
                    </Stack>
                }
            />
            <ContentWithBorders>
                <Suspense fallback={<ActivityTemplateRowSkeletonBars />}>
                    {activityTemplateQuery.isSuccess &&
                        activityTemplateItems.map((item) => (
                            // use accordion without outline and elevation here
                            <Accordion key={item.order} variant="elevation" elevation={0}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Stack direction="row" alignItems="center" spacing={4}>
                                        <SubtaskStatusIcon status={SubTaskStatus.STATUS_OPEN} />
                                        <NoWrapTypography>{translateFromProperty(item, "title", language)}</NoWrapTypography>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MarkdownMentions markdown={translateFromProperty(item, "description", language)} />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    {!activityTemplateQuery.isFetching && noActivitiesInTemplate && (
                        <Typography sx={{ p: 2 }}>{translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_NOT_AVAILABLE)}</Typography>
                    )}
                    {activityTemplateQuery.isFetching && <ActivityTemplateRowSkeletonBars />}
                </Suspense>
            </ContentWithBorders>
            {activityTemplateQuery.isSuccess && noActivitiesInTemplate && (
                <CardActions>
                    <Button color="primary" onClick={feedbackDialog.open}>
                        {translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_ACTIVITIES)}
                    </Button>
                    <FeedbackDialog
                        open={feedbackDialog.isOpen}
                        onClose={feedbackDialog.close}
                        type={FeedbackType.SUGGEST_ACTIVITY_TEMPLATE}
                        title={translate(translationKeys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_ACTIVITIES)}
                        translate={translate}
                    />
                </CardActions>
            )}
        </Card>
    );
};

export default ActivityTemplate;
